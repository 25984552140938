import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ChangeEventHandler } from "react";

interface Props {
  value: string;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const PackagesInput = ({ value, handleChange }: Props) => (
  <Box sx={{ flex: 1, width: "100%" }}>
    <TextField
      id="outlined-multiline-static"
      multiline
      rows={15}
      placeholder={`{
        "name": "packages",
        "version": "1.0.0",
        "dependencies": {
          "@mui/icons-material": "~5.8.0",
          "@mui/material": "~5.8.1",
          "react-dom": "~18.1.0",
          "react-markdown": "^8.0.3",
          "react-query": "^3.39.1",
          "semver": "~7.3.7"
        },
        "devDependencies": {
          "@types/node": "~17.0.36",
          "@types/react": "~18.0.9",
          "@types/react-copy-to-clipboard": "^5.0.2",
          "@types/semver": "~7.3.9",
          "eslint": "~8.16.0",
          "eslint-config-next": "~12.1.6",
          "typescript": "~4.7.2"
        }
      }
      `}
      value={value}
      onChange={handleChange}
      fullWidth
    />
  </Box>
);

export default PackagesInput;
