import type { NextPage } from "next";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Head from "next/head";
import React, { useCallback, useMemo } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "react-query";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Image from "next/image";

import PackagesInput from "../src/components/PackagesInput";
import MinorPatchAccordion from "../src/components/MinorPatchAccordion";
import MajorAccordion from "../src/components/MajorAccordion";
import Copy from "../src/components/Copy";
import { PackagesData } from "../types";
import successLaptopImage from "../public/images/successLaptop.jpg";
import kangarooImage from "../public/images/kangaroo.png";
import Footer from "../src/components/Footer";

const Home: NextPage = () => {
  const [value, setValue] = React.useState<any>();
  const [parseError, setParseError] = React.useState<string>();

  const fetchPackages = async (value: {
    url: string;
    version: string;
    ownerAndName: string;
  }) => {
    const { data } = await axios.post("api/packages", { packages: value });
    return data;
  };

  const {
    data: packagesData,
    error: packagesError,
    isLoading: packagesLoading,
    isFetched: packagesFetched,
  } = useQuery<PackagesData, Error>(
    ["packages", value],
    () => fetchPackages(value),
    {
      enabled: !!value,
    }
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setParseError(undefined);
        if (!event.target.value) {
          return;
        }
        const parsedJson = JSON.parse(event.target.value);
        const hasDependencies = !!parsedJson?.dependencies;
        const hasDevDependencies = !!parsedJson?.devDependencies;
        if (!hasDependencies && !hasDevDependencies) {
          throw new Error();
        }
        setValue(event.target.value);
      } catch (error) {
        // @ts-ignore
        setParseError(`Error parsing JSON: ${error.message}`);
      }
    },
    [setValue]
  );

  const handleClearData = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  const hasMajorPackageUpdates = useMemo(() => {
    return packagesData?.metadata?.some((p) => p.diff === "major");
  }, [packagesData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Upgraderoo
              <Image
                alt={"Kangaroo logo"}
                src={kangarooImage}
                width={"30px"}
                height={"25px"}
              />
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <>
          <Head>
            <title>Automate NPM package updates | Upgraderoo</title>
            <meta
              name="keywords"
              content="npm, dependencies, update, packages, version, npm upgrade, yarn upgrade"
            />
            <meta
              name="description"
              content="Upgraderoo is a tool for automatic batched NPM package updates, increasing productivity with the project dependency updates."
            />
            <meta name="author" content="Janez Čadež" />
            <meta
              property="og:title"
              content="Automate NPM package updates | Upgraderoo"
            />
            <meta
              property="og:description"
              content="Upgraderoo is a tool for automatic batched NPM package updates, increasing productivity with the project dependency updates."
            />
            <meta
              property="og:image"
              content="https://upgraderoo.janez.tech/images/ogSuccessLaptop.jpg"
            />
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: 35, marginBottom: "25px", fontWeight: "bold" }}
              >
                Update NPM packages with ease.{" "}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: 20, marginBottom: "25px" }}
              >
                Upgraderoo is a simple NPM update tool. It helps you upgrade NPM
                packages, discover outdated packages and saves you time to focus
                on building your product.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              <Image alt={"Success Laptop"} src={successLaptopImage} />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: 30, marginBottom: "25px", fontWeight: "bold" }}
              >
                How does it work?
              </Typography>
              <Typography variant="subtitle1">
                Upgraderoo scans your package.json file and queries the NPM
                registry for the latest package version.
              </Typography>
              <Typography variant="subtitle2">
                <Typography sx={{ fontSize: "14px", marginTop: "10px" }}>
                  - If there is an update available, we'll show you the
                  CHANGELOG to help you with the update process.
                </Typography>
                <Typography sx={{ fontSize: "14px", marginTop: "10px" }}>
                  - Breaking changes in CHANGELOG files are shown in red.{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px", marginTop: "10px" }}>
                  - Outdated packages will be shown in the list.{" "}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingBottom: "10px",
                marginTop: "100px",
                marginBottom: "100px",
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: 30, marginBottom: "25px", fontWeight: "bold" }}
              >
                Why Upgraderoo?
              </Typography>
              <Typography variant="subtitle1">
                There are other tools for updating NPM packages, but they are
                usually hard to use and spam your GitHub's PR section.
                Upgraderoo is easy to use, groups all the updates together and
                shows you a clear view of what has changed.
              </Typography>
            </Grid>
          </Grid>
          {!packagesData && !packagesLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontSize: 30, marginBottom: "25px", fontWeight: "bold" }}
              >
                Try it out by pasting your package.json file
              </Typography>
              <PackagesInput value={value} handleChange={handleChange} />
              {parseError && (
                <Box sx={{ marginTop: "20px", color: "red" }}>
                  <Typography>{parseError}</Typography>
                </Box>
              )}
            </Box>
          )}
          {packagesLoading && (
            <Box sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" width="25%" height="50px" />
              <Skeleton animation="wave" height="150px" />
              <Skeleton animation="wave" height="150px" />
              <Skeleton animation="wave" height="150px" />
            </Box>
          )}
          {packagesFetched && packagesError && (
            <Box sx={{ marginTop: "20px" }}>
              <Typography>{packagesError?.message}</Typography>
            </Box>
          )}
          {packagesFetched && !packagesError && (
            <>
              {packagesData?.metadata?.length ? (
                <>
                  <Box>
                    <Typography variant="h5" sx={{ marginTop: "10px" }}>
                      Upgrade guide
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ marginTop: "10px", fontSize: "17px" }}
                    >
                      1. Copy upgraded package.json to your project
                    </Typography>
                    <Copy upgraded={packagesData.upgraded} />
                    <Typography
                      variant="h6"
                      sx={{ marginTop: "10px", fontSize: "17px" }}
                    >
                      2. Run npm install or yarn
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ marginTop: "10px", fontSize: "17px" }}
                    >
                      {hasMajorPackageUpdates
                        ? `3. Address breaking changes in the next packages. View
                      changelog by clicking on the package`
                        : "3. No major package updates. Skip to the next step."}
                    </Typography>
                    <MajorAccordion metadata={packagesData?.metadata} />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ marginTop: "10px", fontSize: "17px" }}
                  >
                    4. Minor and patch packages are listed here and usually
                    don't contain breaking changes. If you have trouble running
                    the app, make sure to check these changes as well.
                  </Typography>
                  <MinorPatchAccordion metadata={packagesData?.metadata} />
                </>
              ) : (
                <Box>
                  <Typography variant="h5">
                    Your packages are up to date!
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
        {packagesData && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: "22px",
                marginTop: "50px",
                marginBottom: "10px",
              }}
            >
              Try with different package.json
            </Typography>
            <Button variant="outlined" onClick={handleClearData}>
              Clear
            </Button>
          </Box>
        )}
        <Footer />
      </Container>
    </Box>
  );
};

export default Home;
