import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Link from "./Link";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingY: "20px",
        marginTop: "500px",
      }}
    >
      <Box>
        <Typography>
          Made with <FavoriteIcon fontSize="small" /> by{" "}
          <Link
            href="https://twitter.com/jamziSLO"
            target={"_blank"}
            color="secondary"
          >
            Janez Čadež
          </Link>
        </Typography>
      </Box>
      <Box>
        <Typography>
          Images:{" "}
          <a href="https://www.freepik.com/vectors/man-computer">Banner</a>,{" "}
          <a href="https://www.flaticon.com/free-icons/kangaroo">Logo</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
