import React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import { Metadata } from "../../types";
import Skeleton from "@mui/material/Skeleton";
import Link from "next/link";
import axios from "axios";
import Chip from "@mui/material/Chip";
import { useQuery } from "react-query";
import { Markdown } from "./Markdown";

interface Props {
  metadata: Metadata[];
}

const MinorPatchAccordion = ({ metadata }: Props) => {
  const [expanded, setExpanded] = React.useState<{
    key: string;
    url: string;
    version: string;
  }>();

  const handleChangeAccordion =
    (panel: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const fetchChangelog = async (props?: any) => {
    const { url, version, ownerAndName } = props;
    const { data } = await axios.post("api/changelog", {
      url,
      currentVersion: version,
      ownerAndName,
    });

    return { data };
  };

  const {
    data: changelogData,
    error: changelogError,
    isLoading: changelogLoading,
  } = useQuery(["changelog", expanded], () => fetchChangelog(expanded), {
    enabled: !!expanded,
  });

  return (
    <>
      {metadata.map((dependency: any) => (
        <Box
          sx={{
            color: dependency.diff === "major" ? "black" : "gray",
            marginTop: "10px",
          }}
          key={dependency.key}
        >
          {dependency.diff === "major" && (
            <Accordion
              expanded={expanded?.key === dependency.key}
              onChange={handleChangeAccordion({
                key: dependency.key,
                url: dependency.changelog,
                version: dependency.from,
                ownerAndName: dependency.ownerAndName,
              })}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ marginRight: "10px" }}>
                    {dependency.key}
                  </Typography>
                  <Chip
                    label={`${dependency.from} -> ${dependency.to}`}
                    sx={{ marginRight: "10px" }}
                  />
                  {dependency.notMaintained && (
                    <Chip
                      color="warning"
                      size="small"
                      icon={<WarningIcon />}
                      label="Not actively maintained"
                    />
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {changelogLoading ? (
                    <>
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" />
                    </>
                  ) : (
                    <>
                      {changelogData?.data?.type === "changelog" && (
                        <Markdown data={changelogData?.data?.data} />
                      )}
                      {changelogData?.data?.type === "releases" && (
                        <Box>
                          {changelogData?.data?.data.map((release: any) => (
                            <Box
                              sx={{ marginBottom: "20px" }}
                              key={release.tag_name}
                            >
                              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                {release.tag_name}
                              </Typography>
                              <Markdown data={release.body} />
                            </Box>
                          ))}
                        </Box>
                      )}
                      {changelogError && (
                        <Box>
                          {dependency.homepage && (
                            <Typography>
                              <Link href={dependency.homepage}>
                                <a target="_blank">{dependency.homepage}</a>
                              </Link>
                            </Typography>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      ))}
    </>
  );
};

export default MinorPatchAccordion;
