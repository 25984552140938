import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const handleBreakingChange = (props: any, Tag: any) => {
  const hasBreaking = props.children?.length
    ? props.children.join("").toUpperCase().includes("BREAKING")
    : false;

  return hasBreaking ? (
    <Tag style={{ color: "red" }}>{props.children}</Tag>
  ) : (
    <Tag>{props.children}</Tag>
  );
};

export const Markdown = ({ data }: { data: any }) => (
  <ReactMarkdown
    linkTarget="_blank"
    remarkPlugins={[remarkGfm]}
    components={{
      h1: (props) => handleBreakingChange(props, "h1"),
      h2: (props) => handleBreakingChange(props, "h2"),
      h3: (props) => handleBreakingChange(props, "h3"),
      h4: (props) => handleBreakingChange(props, "h4"),
      h5: (props) => handleBreakingChange(props, "h5"),
      p: (props) => handleBreakingChange(props, "p"),
      li: (props) => handleBreakingChange(props, "li"),
      strong: (props) => handleBreakingChange(props, "strong"),
    }}
  >
    {data}
  </ReactMarkdown>
);
