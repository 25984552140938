import React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { Metadata } from "../../types";
import Chip from "@mui/material/Chip";
import WarningIcon from "@mui/icons-material/Warning";

interface Props {
  metadata: Metadata[];
}

const MinorPatchAccordion = ({ metadata }: Props) => {
  const [areMinorPatchExpanded, setMinorPatchExpanded] = React.useState(false);

  return (
    <Accordion
      expanded={areMinorPatchExpanded}
      onChange={(e, expanded) => setMinorPatchExpanded(expanded)}
      sx={{ marginTop: "10px" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Minor and patch updates</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {metadata
          .filter((dep: any) => dep.diff !== "major")
          .map((dependency: any) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: dependency.diff === "minor" ? "gray" : "lightgray",
                marginBottom: "10px",
              }}
              key={dependency.key}
            >
              <Typography sx={{ marginRight: "10px" }}>
                {dependency.key}
              </Typography>
              <Chip
                label={`${dependency.from} -> ${dependency.to}`}
                sx={{ marginRight: "10px" }}
              />
              {dependency.notMaintained && (
                <Chip
                  color="warning"
                  size="small"
                  icon={<WarningIcon />}
                  label="Not actively maintained"
                />
              )}
            </Box>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default MinorPatchAccordion;
