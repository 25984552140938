import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  upgraded: string;
}

const Copy = ({ upgraded }: Props) => {
  const [copied, setCopied] = React.useState(false);

  const handleOnCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3 * 1000);
  }, [setCopied]);

  return (
    <CopyToClipboard
      text={JSON.stringify(upgraded, undefined, 2)}
      onCopy={handleOnCopy}
    >
      <Button>
        {copied ? "Copied packages to clipboard" : "Copy packages to clipboard"}
      </Button>
    </CopyToClipboard>
  );
};

export default Copy;
